import news1 from "../../assets/img/1.jpg";
import news2 from "../../assets/img/2.jpg";
import news3 from "../../assets/img/3.jpg";
import news4 from "../../assets/img/4.jpg";
function Kurdistan() {
  return (
    <div dir="rtl">
      <h4>كوردستان</h4>
      {[news1, news2, news3, news4].map((item) => {
        return (
          <div class="row flex my-2">
            <img src={item} alt="" class="col-6 left-image" />
            <h6 class="col-6">ڤیا سەركەفتن ئینا ل سەر ئاكرێ</h6>
          </div>
        );
      })}
      <hr />
      <h4>پتر ھاتینە دیتن</h4>
      {[news1, news2, news3, news4].map((item) => {
        return (
          <div class="row flex my-2">
            <h6 class="col">ڤیا سەركەفتن ئینا ل سەر ئاكرێ</h6>
          </div>
        );
      })}
    </div>
  );
}

export default Kurdistan;
