function NavBar(){
    return (
        <nav class="navbar navbar-expand-lg navbar-dark bg-main p-1 mt-2">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">VIYA MEDIA</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    
      <div class=" collapse navbar-collapse" id="navbarNavDropdown" dir="rtl">
        <ul class="navbar-nav ms-auto ">
          <li class="nav-item">
            <a class="nav-link mx-2 active" aria-current="page" href="#">سەرەكی</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link mx-2 dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            نوچە
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li><a class="dropdown-item" href="#">كوردستان</a></li>
              <li><a class="dropdown-item" href="#">ئێۆاق</a></li>
              <li><a class="dropdown-item" href="#">جیھان</a></li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link mx-2" href="#">راپۆرت</a>
          </li>
          <li class="nav-item">
            <a class="nav-link mx-2" href="#">گۆتار</a>
          </li>
          <li class="nav-item">
            <a class="nav-link mx-2" href="#">ساخلەمی</a>
          </li>
          <li class="nav-item">
            <a class="nav-link mx-2" href="#">ئابۆر</a>
          </li>
          <li class="nav-item">
            <a class="nav-link mx-2" href="#">وەڕزش</a>
          </li>
          <li class="nav-item">
            <a class="nav-link mx-2" href="#">پروگرام</a>
          </li>
          <li class="nav-item">
            <a class="nav-link mx-2" href="#">شڕۆڤەکاری</a>
          </li>
          <li class="nav-item">
            <a class="nav-link mx-2" href="#">هەمەڕەنگ</a>
          </li>
          <li class="nav-item">
            <a class="nav-link mx-2" href="#">تەکنۆلۆژیا</a>
          </li>
          
        </ul>
      </div>
    </div>
    </nav>
    )
}

export default NavBar