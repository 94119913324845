import React from "react";
import Footer from "../components/Footer";
import LastNews from "../components/home/LastNews";
import TopNews from "../components/home/TopNews";
import NavBar from "../components/NavBar";
import Kurdistan from "../components/home/Kurdistan";
import IraqAndWorld from "../components/home/IraqAndWorld";
import Articles from "../components/home/Articles";
import Health from "../components/home/Health";
import Sports from "../components/home/Sports";
import Reports from "../components/home/Reports";
import Location from "../components/home/Location";

function Home() {

  return (
    <div>
      <NavBar />
      <TopNews />
      <div className="container">
        <LastNews />
        <hr />
      </div>
      <div className="py-4  bg-light " dir="rtl">
        <div className="container row flex flex-wrap mx-auto ">
          <div className="col-lg-3">
            <Kurdistan />
          </div>
          <div className="col-lg-9">
            <IraqAndWorld />
          </div>
        </div>
      </div>
      <div className="container">
        <hr />
        <Health />
        <hr />
        <Reports />
        <hr />
        <Sports />
        <hr />
        <Articles />
        <hr />
        
      </div>
      <div class="row">

      </div>
      <Location/>
      <Footer />
    </div>
  );
}

export default Home;
