import news1 from "../../assets/img/1.jpg";
import news2 from "../../assets/img/2.jpg";
import news3 from "../../assets/img/3.jpg";
import news4 from "../../assets/img/4.jpg";
function IraqAndWorld() {
  return (
    <>
      <h4>ئیراق و جیھان</h4>
      <div class=" row ">
        <div class="w-100"></div>
        {[news1, news2, news3, news4].map((item) => {
          return (
            <div class=" col-6  p-2 ">
              <img src={item} alt="" class=" left-image" />
              <h6>ڤیا سەركەفتن ئینا ل سەر ئاكرێ</h6>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default IraqAndWorld;
