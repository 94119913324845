import React from "react";

function Location() {
  return (
    <div class="col-md-6 w-100 my-2">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4384.355823566164!2d42.920282139033326!3d36.88913457971841!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4008f3112f08c943%3A0xdccc3fa936db7a13!2z2YXYudmH2K8g2YHZitinINin2YTYp9mH2YTZitipL9m-25XbjNmF2KfZhtqv25XZh9inINqk24zYpyDbjNinINmG25XYrdqp2YjZhduM!5e0!3m2!1sen!2siq!4v1709634664909!5m2!1sen!2siq"
        width="100%"
        height="450"
        title="Viya Media Location"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default Location;
