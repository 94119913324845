import news1 from "../../assets/img/1.jpg";
import news2 from "../../assets/img/2.jpg";
import news3 from "../../assets/img/3.jpg";
import news4 from "../../assets/img/4.jpg";

function Health() {
  return (
    <div class=" flex row mx-auto pt-2 my-3 w-100" dir="rtl">
      <h4>ساخلەمی</h4>
      {[news1, news2, news3, news4].map((item) => {
        return (
          <div class="col-lg-3 col-md-6">
            <img src={item} alt="" class="left-image" />
            <h5>ڤیا سەركەفتن ئینا ل سەر ئاكرێ</h5>
          </div>
        );
      })}
    </div>
  );
}

export default Health;
