import news1 from "../assets/img/1.jpg";
import news2 from "../assets/img/2.jpg";
import news3 from "../assets/img/3.jpg";
import Carousel from "react-bootstrap/Carousel";

function AppSlider() {
  return (
    <div>
      {/* Carousel */}
      <Carousel indicators={false}>
        <Carousel.Item>
          <img src={news1} alt="" />
          <Carousel.Caption>
            <h3>ڤیا سەركەفتن ئینا ل سەر ئاكرێ</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={news2} alt="" />
          <Carousel.Caption>
            <h3>ڤیا سەركەفتن ئینا ل سەر ئاكرێ</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={news3} alt="" />
          <Carousel.Caption>
            <h3>ڤیا سەركەفتن ئینا ل سەر ئاكرێ</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      {/* Carousel */}
    </div>
  );
}
export default AppSlider;
