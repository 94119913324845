import logo from "../assets/logo.png";

function Footer() {
  return (
    <div
      class="col justify-content-around h-100 text-light bg-main text-center py-3"
      style={{ height: 340 }}
    >
      <img width={100} src={logo} alt="" />
      <p></p>
      <h5>باشترین پورتالا دیجیتال ل کوردستانێ</h5>
      ژبۆ گه‌هاندنا بوویه‌ر و راستیان دێ ھەردەم ل پێشتر بین
      <p></p>
      <br />
      <a href="#" class="btn btn-light">
        دەربارەی مە
      </a>
      <p></p>
      <br />
      ھەمی ماف بو ڤیا میدیا د پاراستی نە
    </div>
  );
}

export default Footer;
