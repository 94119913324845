import news1 from "../../assets/img/1.jpg";
import news2 from "../../assets/img/2.jpg";

import AppSlider from "../Slider";

function TopNews() {
  return (
      <div class="container mt-4" dir="rtl">
        <div class="row">
          <div class="col-lg-8 mb-2">
            <AppSlider/>
          </div>
          <div className=" col-lg-4 col-md-6 justify-content-between img-fluid mx-auto" >
          <img src={news1} alt="" class="left-image"/>
          <p></p>
          <img src={news2} alt=""  class="left-image"/>
          </div>
        </div>
      </div>
  );
}

export default TopNews;
